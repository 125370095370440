import React from 'react';
import Banner2 from '../navigation/Banner2'


const Home = props => {
    return (
        <Banner2 />
    )
}

export default Home